import { BggClient } from "boardgamegeekclient";
import { BggThingDto } from "boardgamegeekclient/dist/esm/dto";
import { BggLinkDto } from "boardgamegeekclient/dist/esm/dto/concrete/subdto";
import { IThingRequest } from "boardgamegeekclient/dist/esm/request";

enum GameLinkType {
  REIMPLEMENTS = "REIMPLEMENTS",
  REIMPLEMENTED_BY = "REIMPLEMENTED_BY",
  EXPANDS = "EXPANDS",
  EXPANDED_BY = "EXPANDED_BY",
  CONTAINS = "CONTAINS",
  CONTAINED_IN = "CONTAINED_IN",
  INTEGRATES_WITH = "INTEGRATES_WITH",
}

export enum GameCreatorRole {
  DESIGNER = "DESIGNER",
  PUBLISHER = "PUBLISHER",
  ARTIST = "ARTIST",
  DEVELOPER = "DEVELOPER",
  GRAPHIC_DESIGNER = "GRAPHIC_DESIGNER",
  SCULPTOR = "SCULPTOR",
  EDITOR = "EDITOR",
  WRITER = "WRITER",
  INSERT_DESIGNER = "INSERT_DESIGNER",
}

export enum BGGLinkType {
  IMPLEMENTATION = "boardgameimplementation",
  INTEGRATION = "boardgameintegration",
  COMPILATION = "boardgamecompilation",
  EXPANSION = "boardgameexpansion",
  CATEGORY = "boardgamecategory",
  MECHANIC = "boardgamemechanic",
  FAMILY = "boardgamefamily",
  PUBLISHER = "boardgamepublisher",
  PERSON = "boardgameperson", // we added this fake one
  ARTIST = "boardgameartist",
  DESIGNER = "boardgamedesigner",
  SOLO_DESIGNER = "boardgamesolodesigner",
  DEVELOPER = "boardgamedeveloper",
  GRAPHIC_DESIGNER = "boardgamegraphicdesigner",
  SCULPTOR = "boardgamesculptor",
  EDITOR = "boardgameeditor",
  WRITER = "boardgamewriter",
  INSERT_DESIGNER = "boardgameinsertdesigner",
}

export type GameData = {
  bgg_id: number;
  name: string;
  description: string;
  thumbnail_url: string;
  image_url: string;
  year: number;
  min_players: number;
  max_players: number;
  min_play_time_minutes: number;
  max_play_time_minutes: number;
  min_age: number;
  complexity: number;
  popularity: number;
  rating: number;
  reimplements: number[];
  reimplemented_by: number[];
  integrations: number[];
  contains: number[];
  contained_in: number[];
  expands: number[];
  expanded_by: number[];
  links: GameLinkData[]; // might not be needed
  classifications: GameClassificationData[];
  creators: GameCreatorData[];
};

export type GameLinkData = {
  bgg_id: number;
  type: GameLinkType;
}

export type GameClassificationData = {
  bgg_id: number;
  external_table: string;
}

export type GameCreatorData = {
  bgg_id: number;
  external_table: string;
  role: GameCreatorRole;
}

export type CreatorData = {
  bgg_id: number;
  name: string;
  bgg_type: string;
};

const client = BggClient.Create();

export async function fetchDataFromBgg(ids: number[]): Promise<BggThingDto[]> {
  const params: IThingRequest = {
    id: ids,
    versions: 0,
    comments: 0,
    marketplace: 0,
    ratingcomments: 0,
    videos: 0,
    stats: 1,
  };

  const allData: BggThingDto[] = [];
  await client.thing.queryWithProgress(params, { limit: 100, }, (data) => {
    allData.push(...data.data);
  });
  return allData;
}

export function mapBggThingToGameData(dto: BggThingDto): GameData {
  const reimplements = dto.links.filter(
    (link) => link.type === BGGLinkType.IMPLEMENTATION && link.inbound
  ).map(l => l.id );
  const reimplemented_by = dto.links.filter(
    (link) => link.type === BGGLinkType.IMPLEMENTATION && !link.inbound
  ).map(l => l.id );
  const integrations = dto.links.filter(
    (link) => link.type === BGGLinkType.INTEGRATION
  ).map(l => l.id );
  const contains = dto.links.filter(
    (link) => link.type === BGGLinkType.COMPILATION && link.inbound
  ).map(l => l.id );
  const contained_in = dto.links.filter(
    (link) => link.type === BGGLinkType.COMPILATION && !link.inbound
  ).map(l => l.id );
  const expands = dto.links.filter(
    (link) => link.type === BGGLinkType.EXPANSION && link.inbound
  ).map(l => l.id );
  const expanded_by = dto.links.filter(
    (link) => link.type === BGGLinkType.EXPANSION && !link.inbound
  ).map(l => l.id );

  const links: GameLinkData[] = dto.links.filter(link => isLink(link)).map(link => ({
    bgg_id: link.id,
    type: bggToGameLinkType(link),
  }));
  const classifications: GameClassificationData[] = dto.links.filter(link => isClassification(link)).map(link => ({
    bgg_id: link.id,
    external_table: link.type,
  }));
  const creators: GameCreatorData[] = dto.links.filter(link => isCreator(link)).map(link => ({
    bgg_id: link.id,
    external_table: isPublisher(link) ? BGGLinkType.PUBLISHER : BGGLinkType.PERSON,
    role: bggToGameCreatorRole(link),
  }));

  return {
    bgg_id: dto.id,
    name: dto.name,
    description: dto.description,
    thumbnail_url: dto.thumbnail,
    image_url: dto.image,
    year: dto.yearpublished,
    min_players: dto.minplayers,
    max_players: dto.maxplayers,
    min_play_time_minutes: dto.minplaytime,
    max_play_time_minutes: dto.maxplaytime,
    min_age: dto.minage,
    complexity: dto.statistics.ratings.averageweight,
    popularity: dto.statistics.ratings.owned,
    rating: dto.statistics.ratings.average,
    reimplements,
    reimplemented_by,
    integrations,
    contains,
    contained_in,
    expands,
    expanded_by,
    links,
    classifications,
    creators,
  };
}


export function mapBggThingToCreatorData(dto: BggThingDto): CreatorData[] {
  let rows: CreatorData[] = [];

  for (const link of dto.links) {
    if ([
        BGGLinkType.ARTIST,
        BGGLinkType.DESIGNER,
        BGGLinkType.SOLO_DESIGNER,
        BGGLinkType.DEVELOPER,
        BGGLinkType.GRAPHIC_DESIGNER,
        BGGLinkType.SCULPTOR,
        BGGLinkType.EDITOR,
        BGGLinkType.WRITER,
        BGGLinkType.INSERT_DESIGNER
      ].indexOf(link.type as BGGLinkType) >= 0) {
      rows.push({
        bgg_id: link.id,
        name: link.value,
        bgg_type: BGGLinkType.PERSON
      });

    } else if (link.type === BGGLinkType.PUBLISHER) {
      rows.push({
        bgg_id: link.id,
        name: link.value,
        bgg_type: BGGLinkType.PUBLISHER
      });
    }
  }
  return rows;
}

function bggToGameLinkType(link: BggLinkDto): GameLinkType {
  switch (link.type) {
    default:
    case BGGLinkType.EXPANSION:
      return link.inbound ? GameLinkType.EXPANDS : GameLinkType.EXPANDED_BY;
    case BGGLinkType.IMPLEMENTATION:
      return link.inbound ? GameLinkType.REIMPLEMENTS : GameLinkType.REIMPLEMENTED_BY;
    case BGGLinkType.INTEGRATION:
      return GameLinkType.INTEGRATES_WITH;
    case BGGLinkType.COMPILATION:
      return link.inbound ? GameLinkType.CONTAINS : GameLinkType.CONTAINED_IN;
  }
}

function bggToGameCreatorRole(link: BggLinkDto): GameCreatorRole {
  switch (link.type) {
    default:
    case BGGLinkType.DESIGNER:
      return GameCreatorRole.DESIGNER;
    case BGGLinkType.PUBLISHER:
      return GameCreatorRole.PUBLISHER;
    case BGGLinkType.ARTIST:
      return GameCreatorRole.ARTIST;
    case BGGLinkType.SOLO_DESIGNER:
      return GameCreatorRole.DESIGNER;
    case BGGLinkType.DEVELOPER:
      return GameCreatorRole.DEVELOPER;
    case BGGLinkType.GRAPHIC_DESIGNER:
      return GameCreatorRole.GRAPHIC_DESIGNER;
    case BGGLinkType.SCULPTOR:
      return GameCreatorRole.SCULPTOR;
    case BGGLinkType.EDITOR:
      return GameCreatorRole.EDITOR;
    case BGGLinkType.WRITER:
      return GameCreatorRole.WRITER;
    case BGGLinkType.INSERT_DESIGNER:
      return GameCreatorRole.INSERT_DESIGNER;
  }
}

function isLink(link: BggLinkDto): boolean {
  const linkTypes = [
    BGGLinkType.IMPLEMENTATION,
    BGGLinkType.INTEGRATION,
    BGGLinkType.COMPILATION,
    BGGLinkType.EXPANSION,
  ];
  return linkTypes.indexOf(link.type as BGGLinkType) !== -1;
}

function isClassification(link: BggLinkDto): boolean {
  const classificationTypes = [
    BGGLinkType.CATEGORY,
    BGGLinkType.MECHANIC,
    BGGLinkType.FAMILY,
  ];
  return classificationTypes.indexOf(link.type as BGGLinkType) !== -1;
}

function isCreator(link: BggLinkDto): boolean {
  return isPerson(link) || isPublisher(link);
}

function isPerson(link: BggLinkDto): boolean {
  const personTypes = [
    BGGLinkType.ARTIST,
    BGGLinkType.DESIGNER,
    BGGLinkType.SOLO_DESIGNER,
    BGGLinkType.DEVELOPER,
    BGGLinkType.GRAPHIC_DESIGNER,
    BGGLinkType.SCULPTOR,
    BGGLinkType.EDITOR,
    BGGLinkType.WRITER,
    BGGLinkType.INSERT_DESIGNER,
  ];
  return personTypes.indexOf(link.type as BGGLinkType) !== -1;
}

function isPublisher(link: BggLinkDto): boolean {
  const publisherTypes = [
    BGGLinkType.PUBLISHER,
  ];
  return publisherTypes.indexOf(link.type as BGGLinkType) !== -1;
}