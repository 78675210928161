import { decode } from "html-entities";
import { CreatorData, GameData } from "./BGGHelper";

const TRUE = "TRUE";
const FALSE = "FALSE";
const NULL = "NULL";
const DELIMITER = "|||";

export function turnSheetRowToCopyStringForGames(sheetRows: GameData[], isExpansions: boolean): string {
  console.log(isExpansions)
  const rows = sheetRows.map((row) => {
    const rowData = [
      row.bgg_id,
      normaliseCharacters(row.name),
      row.year === 0 ? NULL : row.year,
      row.min_players === 0 ? NULL : row.min_players,
      row.max_players === 0 ? NULL : row.max_players,
      row.min_play_time_minutes === 0 ? NULL : row.min_play_time_minutes,
      row.max_play_time_minutes === 0 ? NULL : row.max_play_time_minutes,
      row.min_age === 0 ? NULL : row.min_age,
      row.complexity === 0 ? NULL : row.complexity,
      row.popularity === 0 ? NULL : row.popularity,
      row.rating === 0 ? NULL : row.rating,
      "BOARD_GAME", // domain
      "", // is_base_game
      "", // base_game_id
      isExpansions ? TRUE : FALSE, // is_expansion
      row.reimplements.length > 0 ? TRUE : FALSE, // is_reimplementation
      row.reimplements.join(", "), // reimplements
      isExpansions ? FALSE : TRUE, // is_standalone
      row.integrations.length > 0 ? TRUE : FALSE, // is_interplayable
      row.integrations.join(", "), // integrations
      row.contains.length > 0 ? TRUE : FALSE, // is_compilation
      row.contains.join(", "), // contains
      isExpansions ? row.expands.join(", ") : row.expanded_by.join(", "), // expansions
      "['"+normaliseCharacters(row.name)+"']", // TODO alternate_names
      normaliseCharacters(row.description),
      row.thumbnail_url,
      row.image_url,
    ].join(DELIMITER);
    return `"${rowData}"`;
  });

  return rows.join("\n");
}

export function turnSheetRowToCopyStringForExpansions(sheetRows: GameData[]): string {
  const rows = sheetRows.map((row) => {
    const rowData = [
      row.bgg_id,
      normaliseCharacters(row.name),
      row.year === 0 ? NULL : row.year,
      row.min_players === 0 ? NULL : row.min_players,
      row.max_players === 0 ? NULL : row.max_players,
      row.min_play_time_minutes === 0 ? NULL : row.min_play_time_minutes,
      row.max_play_time_minutes === 0 ? NULL : row.max_play_time_minutes,
      row.min_age === 0 ? NULL : row.min_age,
      row.complexity === 0 ? NULL : row.complexity,
      row.popularity === 0 ? NULL : row.popularity,
      row.rating === 0 ? NULL : row.rating,
      "BOARD_GAME", // domain
      FALSE, // is_base_game
      "", // base_game_id
      TRUE, // is_expansion
      row.reimplements.length > 0 ? TRUE : FALSE, // is_reimplementation
      row.reimplements.join(", "), // reimplements
      FALSE, // is_standalone
      row.integrations.length > 0 ? TRUE : FALSE, // is_interplayable
      row.integrations.join(", "), // integrations
      row.contains.length > 0 ? TRUE : FALSE, // is_compilation
      row.contains.join(", "), // contains
      row.expands.join(", "), // expansion for
      "['"+normaliseCharacters(row.name)+"']", // TODO alternate_names
      normaliseCharacters(row.description),
      row.thumbnail_url,
      row.image_url,
    ].join(DELIMITER);
    return `"${rowData}"`;
  });

  return rows.join("\n");
}


export function turnCreatorRowToCopyString(creatorRows: CreatorData[]): string {
  const rows = creatorRows.map((row) => {
    const rowData = [
      row.bgg_id,
      normaliseCharacters(row.name),
      normaliseCharacters(row.bgg_type),
    ].join(DELIMITER);
    return `"${rowData}"`;
  });

  return rows.join("\n");
}

/**
 * 
 * @param str A string from the boardgamegeekclient DTO, XML format
 * @returns A string that can be pasted in Google Sheets
 */
export function normaliseCharacters(str: string): string {
  // Hacky fix for titles which are numbers
  str = str + " ";
  str.trim();

  // Dunno if it's XML standard or a BGG thing but all of their HTML entities have had their '&' turned into '/&amp;/'.
  // So, we need to turn them back before decoding the rest of the string.
  const ampedUp = str.replace(/&amp;/g, "&");
  const decoded = decode(ampedUp);

  // For making the final string easier to work with in Google Sheets and when migrating in the DB we double both types of quotes.
  const finalResult = decoded.replaceAll("'", "''").replaceAll('"', '""');
  return `${finalResult.trim()}`;
}
