import { decode } from "html-entities";
import { BGGLinkType, GameCreatorRole, GameData } from "./BGGHelper";

type generateSqlOptions = {
  gameDetails: boolean;
  expansions: boolean;
  reimplementations: boolean;
  integrations: boolean;
  compilations: boolean;
  classifications: boolean;
  creators: boolean;
};

export function generateSqlScript(gameData: GameData[], options: generateSqlOptions): string {
  let script: string =
`DO $$ 
DECLARE
actioning_game_id UUID;

BEGIN
`;

  for (const game of gameData) {
    script +=
`
SELECT game_id INTO actioning_game_id FROM external_linked_games WHERE external_source = 'BGG' AND external_identifier = '${game.bgg_id}';
`;

    if (options.classifications && game.classifications && game.classifications.length > 0) {
      const categoryIds = game.classifications.filter(c => c.external_table === BGGLinkType.CATEGORY).map(c => c.bgg_id);
      if (categoryIds.length > 0) {
        script += classificationScript(categoryIds, BGGLinkType.CATEGORY);
      }
      const mechanicIds = game.classifications.filter(c => c.external_table === BGGLinkType.MECHANIC).map(c => c.bgg_id);
      if (mechanicIds.length > 0) {
        script += classificationScript(mechanicIds, BGGLinkType.MECHANIC);
      }
      const familyIds = game.classifications.filter(c => c.external_table === BGGLinkType.FAMILY).map(c => c.bgg_id);
      if (familyIds.length > 0) {
        script += classificationScript(familyIds, BGGLinkType.FAMILY);
      }
    }

    if (options.creators && game.creators && game.creators.length > 0) {
      const designerIds = game.creators.filter(c => c.role === GameCreatorRole.DESIGNER).map(c => c.bgg_id);
      if (designerIds.length > 0) {
        script += creatorScript(designerIds, BGGLinkType.PERSON, GameCreatorRole.DESIGNER);
      }
      const publisherIds = game.creators.filter(c => c.role === GameCreatorRole.PUBLISHER).map(c => c.bgg_id);
      if (publisherIds.length > 0) {
        script += creatorScript(publisherIds, BGGLinkType.PUBLISHER, GameCreatorRole.PUBLISHER);
      }
      const artistIds = game.creators.filter(c => c.role === GameCreatorRole.ARTIST).map(c => c.bgg_id);
      if (artistIds.length > 0) {
        script += creatorScript(artistIds, BGGLinkType.PERSON, GameCreatorRole.ARTIST);
      }
      const developerIds = game.creators.filter(c => c.role === GameCreatorRole.DEVELOPER).map(c => c.bgg_id);
      if (developerIds.length > 0) {
        script += creatorScript(developerIds, BGGLinkType.PERSON, GameCreatorRole.DEVELOPER);
      }
      const graphicDesignerIds = game.creators.filter(c => c.role === GameCreatorRole.GRAPHIC_DESIGNER).map(c => c.bgg_id);
      if (graphicDesignerIds.length > 0) {
        script += creatorScript(graphicDesignerIds, BGGLinkType.PERSON, GameCreatorRole.GRAPHIC_DESIGNER);
      }
      const sculptorIds = game.creators.filter(c => c.role === GameCreatorRole.SCULPTOR).map(c => c.bgg_id);
      if (sculptorIds.length > 0) {
        script += creatorScript(sculptorIds, BGGLinkType.PERSON, GameCreatorRole.SCULPTOR);
      }
      const editorIds = game.creators.filter(c => c.role === GameCreatorRole.EDITOR).map(c => c.bgg_id);
      if (editorIds.length > 0) {
        script += creatorScript(editorIds, BGGLinkType.PERSON, GameCreatorRole.EDITOR);
      }
      const writerIds = game.creators.filter(c => c.role === GameCreatorRole.WRITER).map(c => c.bgg_id);
      if (writerIds.length > 0) {
        script += creatorScript(writerIds, BGGLinkType.PERSON, GameCreatorRole.WRITER);
      }
      const insertDesignerIds = game.creators.filter(c => c.role === GameCreatorRole.INSERT_DESIGNER).map(c => c.bgg_id);
      if (insertDesignerIds.length > 0) {
        script += creatorScript(insertDesignerIds, BGGLinkType.PERSON, GameCreatorRole.INSERT_DESIGNER);
      }
    }
  }

  script += `
END $$;`;

  return script;
}

function classificationScript(bggIds: number[], externalTable: string): string {
  const script = 
`
INSERT INTO games_to_game_classifications (game_id, game_classification_id)
SELECT actioning_game_id, elgc.game_classification_id FROM external_linked_game_classifications elgc
WHERE elgc.external_source = 'BGG' AND elgc.external_identifier IN ('${bggIds.join("','")}') AND external_table = '${externalTable}'
ON CONFLICT (game_id, game_classification_id) DO NOTHING;
`;
  return script;
}

function creatorScript(bggIds: number[], externalTable: BGGLinkType, role: GameCreatorRole): string {
  const script = 
`
INSERT INTO games_to_game_creators (game_id, game_creator_id, role)
SELECT actioning_game_id, elgc.game_creator_id, '${role}' FROM external_linked_game_creators elgc
WHERE elgc.external_source = 'BGG' AND elgc.external_identifier IN ('${bggIds.join("','")}') AND external_table = '${externalTable}'
ON CONFLICT (game_id, game_creator_id, role) DO NOTHING;
`;
  return script;
}

/**
 * 
 * @param str A string from the boardgamegeekclient DTO, XML format
 * @returns A string that can be pasted in Google Sheets
 */
export function normaliseCharacters(str: string): string {
  // Hacky fix for titles which are numbers
  str = str + " ";
  str.trim();

  // Dunno if it's XML standard or a BGG thing but all of their HTML entities have had their '&' turned into '/&amp;/'.
  // So, we need to turn them back before decoding the rest of the string.
  const ampedUp = str.replace(/&amp;/g, "&");
  const decoded = decode(ampedUp);

  // For making the final string easier to work with in Google Sheets and when migrating in the DB we double both types of quotes.
  const finalResult = decoded.replaceAll("'", "''").replaceAll('"', '""')
  return `${finalResult}`;
}
