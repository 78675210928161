import React, { useMemo, useState } from "react";
import Papa from 'papaparse';
import {
  mapBggThingToGameData,
  GameData,
  CreatorData,
  mapBggThingToCreatorData,
  fetchDataFromBgg,
} from "./helpers/BGGHelper";
import { BggThingDto } from "boardgamegeekclient/dist/esm/dto";
import { turnCreatorRowToCopyString, turnSheetRowToCopyStringForExpansions, turnSheetRowToCopyStringForGames } from "./helpers/CopyHelper";
import { generateSqlScript } from "./helpers/DownloadHelper";

const buttonClasses = "relative overflow-hidden inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors bg-gg-blue text-white before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-gg-blue/75 active:text-white/80 disabled:bg-gray-400 disabled:pointer-events-none before:transition-colors";

type ExternalIdLink = { bgg_id: number; ag_id?: string;  };

function App() {
  const [bggIdString, setBggIdString] = useState("");
  const [fileRows, setFileRows] = useState<ExternalIdLink[]>();
  const [type, setType] = useState<"games" | "games-expansions" | "creators">("games");
  const [gameData, setGameData] = useState([] as GameData[]);
  const [expansionData, setExpansionData] = useState([] as GameData[]);
  const [creatorData, setCreatorData] = useState([] as CreatorData[]);
  const [fetching, setFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [typeCopied, setTypeCopied] = useState<"games" | "expansions" | "creators">();
  const [idsAreExpansions, setIdsAreExpansions] = useState(false);

  const inputIds = useMemo(() => {
    if (bggIdString) {
      return bggIdString.split(",").map((v) => parseInt(v));
    } else if (fileRows) {
      return fileRows.map(r => r.bgg_id);
    } else {
      return [];
    }
  }, [bggIdString, fileRows]);

  // file method
  const [fileName, setFileName] = useState<string>();
  const addFile = () => {
    const fileInput: HTMLInputElement = document.getElementById('file-input') as HTMLInputElement;
    const selectedFile = fileInput?.files?.[0];

    if (selectedFile) {
      setErrorMessage("");
      setFileName(selectedFile.name);
      Papa.parse<string[]>(selectedFile, {
        complete: (results, file) => {
          if (results.data.length > 1 && results.data[0][0].toLowerCase() === "bgg_id") {
            const idLinks: ExternalIdLink[] = [];
            const hasAGIDs = results.data[0][1].toLowerCase() === "id";

            for (let i = 1; i < results.data.length; i++) {
              idLinks.push({
                bgg_id: parseInt(results.data[i][0]),
                ag_id: hasAGIDs ? results.data[i][1] : undefined,
              })
            }
            setFileRows(idLinks);
          }
        },
      });
    }
  };
  const removeFile = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.bubbles = false;
    setFileName(undefined);
  };
  // specific ids method
  const onBggIdsChange = (bggIds: string) => {
    setBggIdString(bggIds);
    setErrorMessage("");
  };

  const clearData = () => {
    setGameData([]);
    setExpansionData([]);
    setCreatorData([]);
    setTypeCopied(undefined);
    setIdsAreExpansions(false);
  };

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    clearData();
  
    const type = e.target.value as "games" | "games-expansions" | "creators";
    setType(type);
  };

  const fetchData = async () => {
    clearData();
    setFetching(true);

    if (inputIds.length === 0) {
      return;
    }

    const baseGameDTOs = await fetchDataFromBgg(inputIds);

    if (type === "games") {
      setupGameRows(baseGameDTOs);
    } else if (type === "games-expansions") {
      setupGameRows(baseGameDTOs);
      await setupExpansionRows(baseGameDTOs);
    } else if (type === "creators") {
      setupCreatorRows(baseGameDTOs);
    }
    setFetching(false);
  };

  const setupGameRows = (baseGameDTOs: BggThingDto[]) => {
    if (baseGameDTOs.length > 0) {
      const rows = baseGameDTOs.map(mapBggThingToGameData);
      setGameData(rows);
    } else {
      setErrorMessage("No game info found for " + inputIds.join(","));
    }
  };

  const setupExpansionRows = async (baseGameDTOs: BggThingDto[]) => {
    const baseGameToExpansions = baseGameDTOs.map((dto) => {
      const expansions = dto.links.filter(
        (link) => link.type === "boardgameexpansion" && !link.inbound
      );

      return { bgId: dto.id, expansionIds: expansions.map((ex) => ex.id) };
    });
    const allExpansionIds = baseGameToExpansions.reduce((acc, cur) => {
      return acc.concat(cur.expansionIds);
    }, [] as number[]);
    if (allExpansionIds.length > 0) {
      const expansionDTOS = await fetchDataFromBgg(allExpansionIds);
      const rows = expansionDTOS.map(mapBggThingToGameData);
      setExpansionData(rows);
    }
  };

  const setupCreatorRows = (baseGameDTOs: BggThingDto[]) => {
    if (baseGameDTOs.length > 0) {
      let rows: CreatorData[] = [];
      
      for (const dto of baseGameDTOs) {
        rows = rows.concat(mapBggThingToCreatorData(dto));
      }

      const uniqueRows = rows.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === rows.findIndex(obj => {
          return JSON.stringify(obj) === _value;
        });
      });

      setCreatorData(uniqueRows);
    } else {
      setErrorMessage("No creator info found for " + inputIds.join(","));
    }
  };

  const copyGameData = () => {
    const sheetString = turnSheetRowToCopyStringForGames(gameData, idsAreExpansions);
    navigator.clipboard.writeText(sheetString);
    setTypeCopied("games");
  };

  const copyExpansionData = () => {
    const sheetString = turnSheetRowToCopyStringForExpansions(expansionData);
    navigator.clipboard.writeText(sheetString);
    setTypeCopied("expansions");
  };

  const copyCreatorData = () => {
    const sheetString = turnCreatorRowToCopyString(creatorData);
    navigator.clipboard.writeText(sheetString);
    setTypeCopied("creators");
  };

  const [sqlGameDetails, setSqlGameDetails] = useState(false);
  const [sqlExpansions, setSqlExpansions] = useState(false);
  const [sqlReimplementations, setSqlReimplementations] = useState(false);
  const [sqlIntegrations, setSqlIntegrations] = useState(false);
  const [sqlCompilations, setSqlCompilations] = useState(false);
  const [sqlClassifications, setSqlClassifications] = useState(false);
  const [sqlCreators, setSqlCreators] = useState(false);
  const downloadDisabled = useMemo(() => {
    return !(sqlGameDetails || sqlExpansions || sqlReimplementations || sqlIntegrations || sqlCompilations || sqlClassifications || sqlCreators);
  }, [sqlGameDetails, sqlExpansions, sqlReimplementations, sqlIntegrations, sqlCompilations, sqlClassifications, sqlCreators]);

  const downloadSQL = () => {
    const script: string = generateSqlScript(gameData, {
      gameDetails: sqlGameDetails,
      expansions: sqlExpansions,
      reimplementations: sqlReimplementations,
      integrations: sqlIntegrations,
      compilations: sqlCompilations,
      classifications: sqlClassifications,
      creators: sqlCreators,
    });
    const link = document.createElement("a");
    const file = new Blob([script], { type: 'application/sql' });
    const dateString = new Date().toLocaleDateString();
    link.href = URL.createObjectURL(file);
    link.download = `${dateString.replaceAll("/", "-")} update script.sql`;
    link.click();
    URL.revokeObjectURL(link.href);
 };

  return (
    <div className="m-auto p-6 w-full max-w-[1080px]">
      <form id="file-form" className="flex gap-x-6 mb-4">
        <div className="flex-1 flex-shrink-0">
          <p className={`mb-2 block text-sm font-semibold ${bggIdString.length > 0 ? "text-gray-400" : ""}`}>
            CSV file
          </p>
          <label 
            className={`flex appearance-none rounded-lg cursor-pointer gap-x-2 border border-gray-200 bg-white text-gray-900  focus:border-gg-blue focus:outline-none focus:ring-gg-blue ${bggIdString.length > 0 ? "bg-gray-200 cursor-default" : ""}`}
            htmlFor="file-input">
              <p className={`flex-shrink-0 text-sm bg-gg-blue border border-gg-blue font-semibold text-white rounded-l-lg py-[calc(theme(spacing.2)-1px)] px-4 -ml-px -my-px ${bggIdString.length > 0 ? "bg-gray-400 border-gray-400" : ""}`}>Choose file</p>
              {!fileRows && <p className="flex-auto text-sm text-gray-400 py-[calc(theme(spacing.2)-2px)] px-[calc(theme(spacing.3)-1px)] mt-px">No file chosen.</p>}
              {fileRows && <p className="flex-auto text-sm text-gray-600 font-semibold py-[calc(theme(spacing.2)-2px)] px-[calc(theme(spacing.3)-1px)] mt-px whitespace-nowrap text-ellipsis">{fileName}</p>}
              {fileRows && <button className="flex-shrink-0 self-center mr-1 py-1 px-2 rounded-full hover:bg-gray-100" onClick={removeFile}>✖</button>}
              <input id="file-input" type="file" className="hidden" disabled={bggIdString.length > 0} onChange={addFile} />
          </label>
          <p className="mt-1 text-xs italic text-gray-500 dark:text-gray-500" id="file_input_help">
            Columns: bgg_id, ag_id (optional: only if we have the game in our system already).
          </p>
        </div>
        <p className="pt-10 font-bold text-gray-500">OR</p>
        <div className="flex-1 flex-shrink-0">
          <label 
            htmlFor="id-input"
            className={`mb-2 block text-sm font-semibold ${fileRows !== undefined ? "text-gray-400" : ""}`}
          >
            Specific BGG IDs
          </label>
          <input
            id="id-input"
            type="text"
            className="block w-full appearance-none rounded-lg border border-gray-200 bg-white py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-gray-900 placeholder:text-gray-400 focus:border-gg-blue focus:outline-none focus:ring-gg-blue text-sm disabled:bg-gray-200"
            placeholder="Comma separated..."
            value={bggIdString}
            disabled={fileRows !== undefined}
            onChange={(e) => onBggIdsChange(e.target.value)}
          />
        </div>
      </form>

      <div className="flex justify-end gap-x-4">
        <div className="w-60">
          <label 
            htmlFor="type-select"
            className="mb-2 block text-sm font-semibold"
          >
            Type
          </label>
          <select 
            name="type-select"
            id="type-select"
            className="block w-full appearance-none rounded-lg border border-gray-200 bg-white py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-gray-900 placeholder:text-gray-400 focus:border-gg-blue focus:outline-none focus:ring-gg-blue sm:text-sm"
            value={type}
            onChange={onTypeChange}
            >
            <option value="games">Games</option>
            <option value="games-expansions">Games + Expansions</option>
            <option value="creators">Creator names</option>
          </select>
        </div>
        <div className="self-end">
          <button 
            className={buttonClasses + " w-[120px]"}
            disabled={inputIds.length === 0 || fetching}
            onClick={fetchData}
          >
            {fetching ? "Fetching..." : "Fetch"}
          </button>
        </div>
      </div>
      <p className="h-6 pl-3 text-sm text-right text-gg-red">
        {errorMessage}
      </p>

      {!fetching && gameData.length > 0 && (
        <div className="bg-gg-blue-faint p-6 rounded-md">
          <p className="text-lg font-semibold mb-6">Data fetched for {inputIds.length} game{inputIds.length === 1 ? "" : "s"}</p>
          <p className="text-sm font-semibold mb-2">Copy spreadsheet rows</p>
          <div className="flex gap-2">
            <button 
              className={buttonClasses}
              onClick={copyGameData}
            >
              Copy games ({gameData.length})
            </button>
            {type === "games" && (
              <div className="flex items-center gap-x-2 w-48">
                <input 
                  id="is-expansion-checkbox"
                  type="checkbox"
                  value={idsAreExpansions ? 1 : 0}
                  onChange={() => setIdsAreExpansions(!idsAreExpansions)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-gg-blue focus:ring-2 disabled:text-gray-400"
                />
                <label htmlFor="is-expansion-checkbox" className="ms-2 text-sm font-medium text-gray-900">IDs are expansions</label>
              </div>
            )}
            {type === "games-expansions" && (
              <button 
                className={buttonClasses}
                disabled={expansionData.length === 0}
                onClick={copyExpansionData}
              >
                Copy expansions ({expansionData.length})
              </button>
            )}
            {typeCopied === "games" && <p className="text-sm self-center">Copied games to clipboard!</p>}
            {typeCopied === "expansions" && <p className="text-sm self-center">Copied expansions to clipboard!</p>}
          </div>
          {typeCopied === "expansions" && (
            <div className="mt-6 border-t-2 pt-2">
              <p className="text-sm font-semibold mb-2">Expansion instructions</p>
              <ol className="px-4">
                <li className="list-decimal text-sm mb-1">Go to the "Expansions" tab <a className="text-gg-blue underline" href="https://docs.google.com/spreadsheets/d/1e0uPBDjmSTWB7uPsI8YnKxvmCMJImiN64PUrvHx5msw/edit#gid=1809616464" target="_blank" rel="noreferrer">here</a>.</li>
                <li className="list-decimal text-sm mb-1">In the next empty row (at the bottom of the sheet), paste the copied data into the "bgg_id" column.</li>
                <li className="list-decimal text-sm">Without unselecting the pasted cells, click the floating "Paste" button and then click "Split text to columns".</li>
                <img src="/images/instructions-1.png" alt="Instructions 1" height={220} width={220} className="mb-6" />
                <li className="list-decimal text-sm">Choose the "Custom separator" option.</li>
                <img src="/images/instructions-2.png" alt="Instructions 2" height={220} width={220} className="mb-6" />
                <li className="list-decimal text-sm">Enter three pipe characters: "|||"</li>
                <img src="/images/instructions-3.png" alt="Instructions 3" height={220} width={660} className="mb-6" />
                <li className="list-decimal text-sm">Once this is done you will have finished with this game! 🎉</li>
              </ol>
            </div>
          )}
          <p className="text-sm font-semibold mt-10 mb-2">Download SQL script</p>
          <div className="flex gap-x-6 mb-2">
            <div className="flex items-center gap-x-2 w-48">
              <input 
                disabled
                id="game-details-checkbox"
                type="checkbox"
                value={sqlGameDetails ? 1 : 0}
                onChange={() => setSqlGameDetails(!sqlGameDetails)}
                className="w-4 h-4 text-gg-blue bg-gray-100 border-gray-300 rounded focus:ring-gg-blue focus:ring-2 disabled:text-gray-400"
              />
              <label htmlFor="game-details-checkbox" className="ms-2 text-sm font-medium text-gray-400">Game details</label>
            </div>
            <div className="flex items-center gap-x-2 w-48">
              <input 
                id="classifications-checkbox"
                type="checkbox"
                value={sqlClassifications ? 1 : 0}
                onChange={() => setSqlClassifications(!sqlClassifications)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-gg-blue focus:ring-2 disabled:text-gray-400"
              />
              <label htmlFor="classifications-checkbox" className="ms-2 text-sm font-medium text-gray-900">Classifications</label>
            </div>
            <div className="flex items-center gap-x-2 w-48">
              <input 
                id="creators-checkbox"
                type="checkbox"
                value={sqlCreators ? 1 : 0}
                onChange={() => setSqlCreators(!sqlCreators)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-gg-blue focus:ring-2 disabled:text-gray-400"
              />
              <label htmlFor="creators-checkbox" className="ms-2 text-sm font-medium text-gray-900">Creators</label>
            </div>
          </div>
          <div className="flex gap-x-6 mb-4">
            <div className="flex items-center gap-x-2 w-48">
              <input 
                disabled
                id="expansions-checkbox"
                type="checkbox"
                value={sqlExpansions ? 1 : 0}
                onChange={() => setSqlExpansions(!sqlExpansions)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-gg-blue focus:ring-2 disabled:text-gray-400"
              />
              <label htmlFor="expansions-checkbox" className="ms-2 text-sm font-medium text-gray-400">Expansions</label>
            </div>
            <div className="flex items-center gap-x-2 w-48">
              <input 
                disabled
                id="reimplementations-checkbox"
                type="checkbox"
                value={sqlReimplementations ? 1 : 0}
                onChange={() => setSqlReimplementations(!sqlReimplementations)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-gg-blue focus:ring-2 disabled:text-gray-400"
              />
              <label htmlFor="reimplementations-checkbox" className="ms-2 text-sm font-medium text-gray-400">Reimplementations</label>
            </div>
            <div className="flex items-center gap-x-2 w-48">
              <input 
                disabled
                id="integrations-checkbox"
                type="checkbox"
                value={sqlIntegrations ? 1 : 0}
                onChange={() => setSqlIntegrations(!sqlIntegrations)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-gg-blue focus:ring-2 disabled:text-gray-400"
              />
              <label htmlFor="integrations-checkbox" className="ms-2 text-sm font-medium text-gray-400">Integrations</label>
            </div>
            <div className="flex items-center gap-x-2 w-48">
              <input 
                disabled
                id="compilations-checkbox"
                type="checkbox"
                value={sqlCompilations ? 1 : 0}
                onChange={() => setSqlCompilations(!sqlCompilations)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-gg-blue focus:ring-2 disabled:text-gray-400"
              />
              <label htmlFor="compilations-checkbox" className="ms-2 text-sm font-medium text-gray-400">Compilations</label>
            </div>
          </div>
          <button 
            className={buttonClasses}
            disabled={downloadDisabled}
            onClick={downloadSQL}
          >
            Download
          </button>
        </div>
      )}

      {!fetching && creatorData.length > 0 && (
        <div className="bg-gg-blue-faint p-6 rounded-md">
        <p className="text-lg font-semibold mb-6">Data fetched for {inputIds.length} game{inputIds.length === 1 ? "" : "s"}</p>
        <p className="text-sm font-semibold mb-2">Copy spreadsheet rows</p>
        <div className="flex gap-2">
          <button 
            className={buttonClasses}
            onClick={copyCreatorData}
          >
            Copy creators ({creatorData.length})
          </button>
          {typeCopied === "creators" && <p className="text-sm self-center">Copied creators to clipboard!</p>}
        </div>
      </div>
      )}
    </div>
  );
}

export default App;
